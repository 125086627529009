module.exports = {
  selectLanguage: "selecione sua lingua",
  displayName: "idioma portugues",
  categories: "Categorias",
  read: "Ler",
  price: "Preço",
  lastUpdate: "Última atualização",
  free: "Agora disponível gratuitamente",
  Software: "Programas",
  "Machine Learning": "Machine Learning",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "Tag",
  Home: "Pagina inicial",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Formulário de Contato",
  "Cancel": "Cancelar",
  "Submit": "Enviar",
  "Contact Message": "Agradecemos se você nos der algum feedback sobre o nosso site.",
  "Flutter": "Flutter"
}
